import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux'

import { Layout } from '../../components/layout.component';
import { Button } from '../../components/button.component';
import { FormControl } from '../../components/form-control.component';
import { Input } from '../../components/input.component';
import { Select } from '../../components/select.component';
import { DatePick } from '../../components/datepick.component';
import { TermsCheckbox } from '../../components/terms-checkbox.component';
import { FormContext } from '../../utils/form.context';
import { createAccount } from '../../services/users.service';
import { setUser } from '../../states/authentication.slice';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Link } from 'gatsby';

import { RegisterLayout } from '../../components/register-layout.component';

export default function CreateAccount() {

    const { t, navigate } = useI18next();
    const [isChecked, setIsChecked] = useState(false);
    const dispatch = useDispatch();


    const [formValues, setFormValues] = useState({});
    
    const [errors, setErrors] = useState(null);

    const onCreateAccount = useCallback((e) => {
        e.preventDefault();
        if (isChecked) {
            setErrors(null);
            createAccount(formValues)
                .then(response => {
                    dispatch(setUser(response.data));
                    navigate('/account/verify');
                })
                .catch(error => setErrors(error.response.data.message));
        }
    }, [formValues, setErrors, dispatch, navigate]);

    const handleOnChange = useCallback(e => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }, [formValues, setFormValues]);

    const handleOnDateOfBirthChange = useCallback(date => {
        const dateOfBirth = { ...formValues.dateOfBirth, date: date };
        //split and set month+day values
        dateOfBirth.day = date.getDate();
        dateOfBirth.month = date.getMonth() + 1;
        //needs to be string for select input, otherwise it doesn't match
        dateOfBirth.year = String(date.getFullYear());
        setFormValues({ ...formValues, dateOfBirth: dateOfBirth });
    }, [formValues, setFormValues]);

    const handleOnYearOfBirthChange = useCallback(e => {
        const value = e?.target?.value ? e.target.value : '';
        const dateOfBirth = { ...formValues.dateOfBirth, [e.target.name]: value };
        if (value !== '') {
            if (!dateOfBirth.date) {
                dateOfBirth.date = new Date();
            }
            dateOfBirth.date.setYear(value);
        }
        setFormValues({ ...formValues, dateOfBirth: dateOfBirth });
    }, [formValues, setFormValues]);


    const startYear = 1900;
    const endYear = new Date().getFullYear();

    return <RegisterLayout disableSecureLayout={true} title={t('Create account')} className="create-account" backLocation="/account/register">
        <div className="section section--create-account">
            <FormContext.Provider value={errors}>
                <form onSubmit={onCreateAccount} className="flex-form">
                    <FormControl label={t('Full Name')} className="half-width" name="lastName">
                        <Input name="lastName" placeholder={t("姓")}  value={formValues.lastName||''} onChange={handleOnChange} />
                    </FormControl>
                    <FormControl className="half-width" name="firstName">
                        <Input name="firstName" placeholder={t("名")} value={formValues.firstName||''} onChange={handleOnChange} />
                    </FormControl>
                    <FormControl label={t('Furigana')} className="half-width" name="lastNameJa">
                        <Input name="lastNameJa" placeholder={t("ヤマダ")}  value={formValues.lastNameJa||''} onChange={handleOnChange} />
                    </FormControl>
                    <FormControl className="half-width" name="firstNameJa">
                        <Input name="firstNameJa" placeholder={t("タロウ")} value={formValues.firstNameJa||''} onChange={handleOnChange} />
                    </FormControl>
                    <FormControl label={t('Email')} name="emailAddress">
                        <Input name="emailAddress" placeholder="taro.yamada@gmail.com"  value={formValues.emailAddress||''} onChange={handleOnChange} />
                    </FormControl>
                    <FormControl label={t('Create password')} name="password">
                        <Input name="password" placeholder="* * * * * * *"  value={formValues.password||''} onChange={handleOnChange} type="password" />
                    </FormControl>
                    <FormControl label={t('Re-type password')} name="repeatPassword">
                        <Input name="repeatPassword" placeholder="* * * * * * *" value={formValues.repeatPassword||''} onChange={handleOnChange} type="password" />
                    </FormControl>
                    <FormControl label={t('Date of birth')} name="dateOfBirth">
                        <div className="date-of-birth">
                            <DatePick
                                placeholder={t('Date (day-month)')}
                                startYear={startYear} endYear={endYear}
                                value={formValues.dateOfBirth?.date}
                                onChange={handleOnDateOfBirthChange} />
                            <Select name="year" value={formValues.dateOfBirth?.year || ''} onChange={handleOnYearOfBirthChange}>
                                <option value=""></option>
                                {Array.from({ length: endYear - startYear + 1 }, (_, i) => <option key={endYear - i} value={endYear - i}>{endYear - i}</option>)}
                            </Select>
                        </div>
                    </FormControl>
                    <div className="terms-and-conditions">
                        <TermsCheckbox align="flex" label={t('Please read the inspection rules, and if you agree, click the check box to proceed to the next step')} checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
                        <a className="t-link t-green t-sans t-bold terms-and-conditions__link" href="/terms-and-conditions" target="_blank">{t('Terms and conditions')}</a>
                    </div>
                    <div className="buttons">
                        <Button className={isChecked ? '' : 'disabled'} type="submit">{t('Create account')}</Button>
                    </div>
                </form>
            </FormContext.Provider>
        </div>
    </RegisterLayout>;
}
