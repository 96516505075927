import React, { useEffect, useRef, useState } from 'react';

import './select.component.scss';

export const Select = ({ children, className='hap-select', value, ...rest }) => {

    const selectReference = useRef();

    const [ selectedText, setSelectedText ] = useState();

    useEffect(() => {
        if(selectReference.current) {
            if(typeof selectReference.current === 'object') {
                const children = selectReference.current.children;
                const keys = Object.keys(selectReference.current.children);
                for(let i in keys) {
                    const key = keys[i];
                    if(children[key].value === value) {
                        setSelectedText(children[key].innerHTML);
                    }
                }
            }
            // selectReference.current.onchange();
        }
    }, [ selectReference, value, setSelectedText ]);

    return <div className={className}>
        { selectedText }
        <select ref={selectReference} value={value} { ...rest }>
            { children }
        </select>
    </div>;
};